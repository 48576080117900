import React from "react"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"

const Footer = () => (
  <footer>
    <div className="row">
      <div className="col-lg-4 footer-info">
        <Link to="/" className="navbar_brand">
          <LazyLoad once height={45}>
            <img
              className="img-fluid"
              src={require("../assets/images/logo_light.svg")}
              alt=""
            />
          </LazyLoad>
        </Link>
        <p>
          Zleć zarządzanie swoją stroną nam, a ty skup się w tym czasie w pełni
          na swoim biznesie!
        </p>
        <Link to="/cennik/" className="btn_custom yellow">
          &#60;Sprawdź ofertę/&#62;
        </Link>
      </div>

      <div className="col-lg-6 footer-menu">
        <div className="row">
          <div className="col-6 col-lg-4 footer-menu__item">
            <h4>Mapa strony</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/cennik/">Cennik</Link>
              </li>
              <li>
                <Link to="/uslugi/">Usługi</Link>
              </li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
              <li>
                <Link to="/referencje/">Referencje</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-4 footer-menu__item">
            <h4>Pomoc</h4>
            <ul>
              <li>
                <Link to="/faq/">FAQ</Link>
              </li>
              <li>
                <Link to="/kontakt/">Kontakt</Link>
              </li>
              <li>
                <Link to="/program-partnerski/">Program partnerski</Link>
              </li>
              <li>
                <Link to="/polityka-cookies/">Polityka cookies</Link>
              </li>
              <li>
                <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-4 footer-menu__item">
            <h4>Kontakt</h4>
            <a href="tel:+48690888692">+48 690 888 692</a>
            <a href="mailto:kontakt@forsecure.pl">kontakt@forsecure.pl</a>
          </div>
        </div>
      </div>
    </div>

    <div className="row copyrights">
      <div className="col-12 col-md-6 copyrights__text">
        Copyrights &copy; {new Date().getFullYear()} ForSecure
      </div>
      <div className="col-12 col-md-6 text-right">
        <a
          href="https://adream.pl/"
          className="development"
          target="_blank"
          rel="noopener noreferrer"
        >
          Proudly powered by
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
