import React from "react"
import { Link } from "gatsby"
import { CookiesProvider, withCookies } from "react-cookie"

import Header from "./header"
import Footer from "./footer"

import "../assets/sass/main.scss"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

class Layout extends React.Component {
  state = {
    cookieOpen: false,
  }
  componentDidMount() {
    const { cookies } = this.props
    const isAcceptedCoookie = !!cookies.get("cookie-accept")
    !isAcceptedCoookie && this.setState({ cookieOpen: true })
  }

  acceptCookie = () => {
    const { cookies } = this.props

    const promiseSetCookie = new Promise(resolve =>
      resolve(cookies.set("cookie-accept", "active", { path: "/" }))
    )
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false })
    })
  }

  render() {
    const { children } = this.props
    return (
      <CookiesProvider>
        <Header />
        <div className="code_shapes">
          <span className="code_shape_1"></span>
          <span className="code_shape_2"></span>
          <span className="code_shape_3"></span>
          <span className="code_shape_4"></span>
          <span className="code_shape_5"></span>
        </div>
        {children}
        <Footer />
        <div
          className={`cookie_baner${this.state.cookieOpen ? " open" : ""}`}
          id="mainBelt"
        >
          <p>
            Ta strona wykorzystuje pliki Cookies do poprawnego działania.{" "}
            <Link
              to="/polityka-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Więcej
            </Link>
          </p>
          <button className="btn_custom" onClick={this.acceptCookie}>
            &#60;Akceptuje/&#62;
          </button>
        </div>
      </CookiesProvider>
    )
  }
}

export default withCookies(Layout)
