import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import $ from "jquery"

import Logo from "./logo"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuShrink, setMenuShrink] = useState(false)

  useEffect(() => {
    $(window).scroll(function() {
      if ($(window).scrollTop() > 1) {
        setMenuShrink(true)
      } else {
        setMenuShrink(false)
      }
    })
  })

  const menuToggle = () => {
    setMenuOpen(!menuOpen)

    $("body").toggleClass("overflow-hidden")
  }

  const removeOverflow = () => {
    $("body").removeClass("overflow-hidden")
  }

  return (
    <header
      className={` ${menuOpen ? "header-open" : ""} ${
        menuShrink ? "shrink" : ""
      } `}
    >
      <Link to="/" className="navbar_brand" onClick={() => removeOverflow()}>
        {/* <img
          className="img-fluid"
          src={require("../assets/images/logo.svg")}
          alt=""
        /> */}
        <Logo />
      </Link>
      <nav className={menuOpen ? "open" : null}>
        <ul>
          <li>
            <Link
              to="/"
              activeClassName="active"
              onClick={() => removeOverflow()}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/cennik/"
              activeClassName="active"
              onClick={() => removeOverflow()}
            >
              Cennik
            </Link>
          </li>
          <li>
            <Link
              to="/uslugi/"
              activeClassName="active"
              onClick={() => removeOverflow()}
            >
              Usługi
            </Link>
          </li>
          <li>
            <Link
              to="/blog/"
              activeClassName="active"
              onClick={() => removeOverflow()}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="/referencje/"
              activeClassName="active"
              onClick={() => removeOverflow()}
            >
              Referencje
            </Link>
          </li>
          <li>
            <Link
              to="/faq/"
              activeClassName="active"
              onClick={() => removeOverflow()}
            >
              FAQ
            </Link>
          </li>
          <li>
            <Link
              to="/kontakt/"
              activeClassName="active"
              onClick={() => removeOverflow()}
            >
              Kontakt
            </Link>
          </li>
          <li className="divider">
            <span></span>
          </li>
          <li>
            <a href="https://panel.forsecure.pl/login" className="btn-mobile">
              Zaloguj się
            </a>
          </li>
          <li>
            <a
              href="https://panel.forsecure.pl/register"
              className="btn_custom nav"
            >
              &#60;Rejestracja/&#62;
            </a>
          </li>
        </ul>
      </nav>
      <button
        className={`header-burger  ${menuOpen ? "open" : null}`}
        onClick={() => menuToggle()}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  )
}
export default Header
