import React from "react"


const Logo = () => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" className="logo"
        viewBox="0 0 291.3 79.6">
    <g>
        <path class="st0" d="M101.9,29.5h4.2c0.4,0,0.7,0.1,0.9,0.2c0.2,0.2,0.2,0.4,0.2,0.8v3.6c0,0.7-0.4,1.1-1.1,1.1h-4.2
            c-0.4,0-0.6,0.2-0.6,0.6v19.3c0,0.7-0.4,1.1-1.2,1.1h-4.4c-0.7,0-1.1-0.4-1.1-1.1V35.8c0-0.4-0.2-0.6-0.6-0.6h-2.4
            c-0.7,0-1.1-0.4-1.1-1.1v-3.6c0-0.4,0.1-0.7,0.2-0.8c0.2-0.1,0.4-0.2,0.9-0.2H94c0.4,0,0.6-0.2,0.6-0.6v-1c0-2.8,0.7-4.9,2.2-6.3
            c1.5-1.5,3.6-2.2,6.3-2.2h3c0.4,0,0.7,0.1,0.8,0.2c0.1,0.2,0.2,0.4,0.2,0.8V24c0,0.7-0.4,1.1-1.1,1.1h-1.9c-1.1,0-1.9,0.2-2.3,0.7
            c-0.4,0.5-0.6,1.3-0.6,2.4v0.7C101.3,29.3,101.5,29.5,101.9,29.5z"/>
        <path class="st0" d="M123.5,56.3h-4.7c-2.8,0-4.9-0.7-6.4-2.2c-1.5-1.5-2.3-3.6-2.3-6.3V38c0-2.8,0.8-4.9,2.3-6.3
            c1.5-1.5,3.6-2.2,6.4-2.2h4.7c2.7,0,4.9,0.7,6.4,2.2c1.5,1.5,2.3,3.6,2.3,6.3v9.7c0,2.7-0.8,4.8-2.3,6.3
            C128.4,55.5,126.3,56.3,123.5,56.3z M119.9,50.5h2.7c1.1,0,1.9-0.2,2.4-0.7c0.5-0.5,0.7-1.2,0.7-2.4v-9.2c0-1.1-0.2-1.9-0.7-2.4
            c-0.5-0.5-1.3-0.7-2.4-0.7h-2.7c-1.1,0-1.9,0.2-2.4,0.7c-0.5,0.5-0.7,1.2-0.7,2.4v9.2c0,1.1,0.2,1.9,0.7,2.4
            C117.9,50.3,118.7,50.5,119.9,50.5z"/>
        <path class="st0" d="M142.4,56.3h-4.5c-0.7,0-1.1-0.4-1.1-1.1V30.6c0-0.7,0.4-1.1,1.1-1.1h4.5c0.7,0,1.1,0.4,1.1,1.1v1.8h0.2
            c0.4-0.9,1.1-1.7,2-2.2c1-0.5,2-0.7,3.1-0.7h1.9c0.7,0,1.1,0.4,1.1,1.1v3.6c0,0.4-0.1,0.7-0.2,0.9c-0.2,0.2-0.4,0.2-0.8,0.2h-3.6
            c-2.4,0-3.6,1.4-3.6,4.1v15.8C143.5,55.9,143.1,56.3,142.4,56.3z"/>
        <path class="st0" d="M166.2,56.3h-10c-0.7,0-1.1-0.4-1.1-1.1v-3.4c0-0.4,0.1-0.7,0.2-0.8c0.1-0.2,0.4-0.2,0.8-0.2h8.8
            c1.7,0,2.6-0.5,2.6-1.6v-0.3c0-0.8-0.7-1.6-2.2-2.4l-5.2-2.9c-1.8-0.9-3.1-1.9-3.9-2.9c-0.8-1-1.3-2.4-1.3-4c0-2.4,0.6-4.3,1.9-5.4
            c1.3-1.2,3.3-1.7,6.1-1.7h8.8c0.7,0,1,0.4,1,1.1V34c0,0.4-0.1,0.7-0.2,0.8c-0.1,0.2-0.4,0.2-0.8,0.2h-7.3c-1.7,0-2.6,0.4-2.6,1.3
            v0.3c0,0.8,0.8,1.6,2.3,2.4l5.3,3.1c1.8,1,3.1,2,3.8,3c0.7,1,1.1,2.4,1.1,4.1C174.4,53.9,171.7,56.3,166.2,56.3z"/>
        <path class="st0" d="M198,56.3h-11.6c-2.8,0-4.9-0.7-6.4-2.2c-1.5-1.5-2.3-3.6-2.3-6.3V38c0-2.8,0.8-4.9,2.3-6.3
            c1.5-1.5,3.6-2.2,6.4-2.2h4.1c2.8,0,4.9,0.7,6.4,2.2c1.5,1.5,2.3,3.6,2.3,6.3v6.7c0,0.7-0.4,1.1-1.1,1.1h-13.2
            c-0.4,0-0.6,0.2-0.6,0.6v1.2c0,1.1,0.2,1.9,0.7,2.3c0.5,0.5,1.3,0.7,2.4,0.7H198c0.7,0,1.1,0.4,1.1,1.2v3.5c0,0.4-0.1,0.7-0.2,0.8
            S198.4,56.3,198,56.3z M184.9,41.3h7.1c0.4,0,0.6-0.2,0.6-0.6v-2.5c0-1.1-0.2-1.9-0.7-2.4c-0.5-0.5-1.2-0.7-2.4-0.7h-2.2
            c-1.1,0-1.9,0.2-2.4,0.7c-0.5,0.5-0.7,1.2-0.7,2.4v2.5C184.3,41.2,184.5,41.3,184.9,41.3z"/>
        <path class="st0" d="M220.5,51.6v3.6c0,0.4-0.1,0.7-0.2,0.8c-0.2,0.2-0.4,0.2-0.9,0.2h-7.8c-2.8,0-4.9-0.7-6.4-2.2
            c-1.5-1.5-2.3-3.6-2.3-6.3V38c0-2.8,0.8-4.9,2.3-6.3c1.5-1.5,3.6-2.2,6.4-2.2h7.8c0.7,0,1.1,0.4,1.1,1.1v3.6c0,0.4-0.1,0.7-0.2,0.8
            c-0.2,0.2-0.4,0.2-0.9,0.2h-6.8c-1.1,0-1.9,0.2-2.3,0.7c-0.5,0.5-0.7,1.3-0.7,2.4v9c0,1.1,0.2,1.9,0.7,2.4c0.5,0.5,1.3,0.7,2.3,0.7
            h6.8C220.1,50.5,220.5,50.8,220.5,51.6z"/>
        <path class="st0" d="M232.9,56.3h-1.7c-2.7,0-4.7-0.7-6.1-2.2c-1.4-1.5-2.1-3.6-2.1-6.3V30.6c0-0.7,0.4-1.1,1.1-1.1h4.5
            c0.7,0,1.1,0.4,1.1,1.1v16.8c0,1.1,0.2,1.9,0.7,2.4c0.5,0.5,1.3,0.7,2.3,0.7h1.9c2.4,0,3.6-1.3,3.6-4V30.6c0-0.7,0.4-1.1,1.1-1.1
            h4.4c0.8,0,1.2,0.4,1.2,1.1v24.6c0,0.7-0.4,1.1-1.2,1.1h-4.4c-0.7,0-1.1-0.4-1.1-1.1v-1.7h-0.2c-0.4,0.9-1.1,1.7-2,2.1
            C235.1,56,234,56.3,232.9,56.3z"/>
        <path class="st0" d="M255.1,56.3h-4.5c-0.7,0-1.1-0.4-1.1-1.1V30.6c0-0.7,0.4-1.1,1.1-1.1h4.5c0.7,0,1.1,0.4,1.1,1.1v1.8h0.2
            c0.4-0.9,1.1-1.7,2-2.2c1-0.5,2-0.7,3.1-0.7h1.9c0.7,0,1.1,0.4,1.1,1.1v3.6c0,0.4-0.1,0.7-0.2,0.9c-0.2,0.2-0.4,0.2-0.8,0.2h-3.6
            c-2.4,0-3.6,1.4-3.6,4.1v15.8C256.2,55.9,255.8,56.3,255.1,56.3z"/>
        <path class="st0" d="M286.4,56.3h-11.6c-2.8,0-4.9-0.7-6.4-2.2c-1.5-1.5-2.3-3.6-2.3-6.3V38c0-2.8,0.8-4.9,2.3-6.3
            c1.5-1.5,3.6-2.2,6.4-2.2h4.1c2.8,0,4.9,0.7,6.4,2.2c1.5,1.5,2.3,3.6,2.3,6.3v6.7c0,0.7-0.4,1.1-1.1,1.1h-13.2
            c-0.4,0-0.6,0.2-0.6,0.6v1.2c0,1.1,0.2,1.9,0.7,2.3c0.5,0.5,1.3,0.7,2.4,0.7h10.6c0.7,0,1.1,0.4,1.1,1.2v3.5c0,0.4-0.1,0.7-0.2,0.8
            C287.2,56.2,286.9,56.3,286.4,56.3z M273.4,41.3h7.1c0.4,0,0.6-0.2,0.6-0.6v-2.5c0-1.1-0.2-1.9-0.7-2.4c-0.5-0.5-1.2-0.7-2.4-0.7
            h-2.2c-1.1,0-1.9,0.2-2.4,0.7c-0.5,0.5-0.7,1.2-0.7,2.4v2.5C272.8,41.2,273,41.3,273.4,41.3z"/>
    </g>
    <path class="st1" d="M30.7,61.1l0.5,0.3l0.6,0.1c7.7,0.9,13.4-0.5,17.7-2.8c8.8-4.6,11.5-13,11.9-14.2l8.1-26.1L49.6,1.7L23.9,4.2
        l-8.1,26.1C15.2,32.1,10,50.1,30.7,61.1z M20.9,32.1l7.1-22.8l19.9-1.9l15.4,12.8l-7,22.6l0,0.2c-0.2,0.6-4,15.1-23.4,13.2
        C15.8,46.8,20.7,32.7,20.9,32.1z M72.4,24.2l0.1-0.2c6.4,15.1,2,32.4-10.2,42.8c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
        c-5.2,4.1-11.5,6.9-18.5,7.8l0.1-0.1C28,77.1,12.9,69.8,5.5,55.7c-3-5.8-4.4-11.9-4.3-18l-0.1,0.1c0.2-9.2,3.7-17.8,9.6-24.2
        c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.5-0.5,0.8-0.8c0.2-0.2,0.4-0.4,0.6-0.6c0.1-0.1,0.3-0.3,0.4-0.4c0.3-0.3,0.6-0.6,0.9-0.9
        c0,0,0.1-0.1,0.1-0.1c1.6-1.4,3.4-2.7,5.3-3.9l0,0c0.2-0.1,0.4-0.2,0.6-0.3l-3,9.7c-0.3,0.3-0.6,0.6-0.9,0.9c-0.5,0.5-0.9,1-1.3,1.5
        c0,0.1-0.1,0.1-0.1,0.2C6.5,28,4.7,41.5,10.8,52.9c8,15.2,26.8,21,42,13c1.4-0.7,2.7-1.6,3.9-2.5c0.2-0.2,0.5-0.4,0.7-0.5
        c0.3-0.3,0.6-0.5,0.9-0.8c0.5-0.4,0.9-0.8,1.4-1.2c0.1-0.1,0.1-0.1,0.2-0.2c6.3-6.1,9.9-14.9,9.3-24.3c-0.1-1.4-0.3-2.7-0.5-4l3-9.8
        C71.9,23.2,72.1,23.7,72.4,24.2z M41.2,28.9l-10.3,0.9l-4.6,14.5c0,0.1-0.2,0.5-0.3,1.1c-1.3-2.5-2.3-5.8-2.4-10.5c0,0,0,0,0,0
        l7-21.9l16-1.5l-1.7,5.6l-10.2,1l-2,6.2l10.2-0.8L41.2,28.9z"/>
    </svg>
)

export default Logo